<template>
	<div>
		<div class="filter-box">
			<select	id="sectorFilter" class="filter"
				v-model="selectedSector"
				@change="filterChange">
				<option value="all">Todos los sectores</option>
				<option v-for="s in sectores" :key="s">{{ s }}</option>
			</select>
			<select id="marginFilter" class="filter" v-model="typeMg" @change="filterChange">
				<option value="all" selected="true">Todos los margenes</option>
				<option value="normal">Normal</option>
				<option value="ns">Sin Venta</option>
				<option value="nc">Sin Coste</option>
				<option value="lt">&lt;-300%</option>


			</select>
		</div>

		<img id="logo" :src="logoConTexto" alt="TiempoClickLogo" @click="back()" />

		<div
			ref="chartContainer"
			class="chart-container"
			:style="{ width: '100%', height: winH }"></div>
		<div id="myModal" class="modal">
			<div class="modal-content">
				<span class="close">&times;</span>
				<div id="barChartContainer" style="width: 100%; height: 100%"></div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted, createApp, defineProps } from "vue";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import ReportService from "@/services/ReportService";
import ChartTooltip from "@/components/chart/ChartTooltip.vue";
import logoConTexto from "@/assets/img/LogoTexto.png";
import router from "@/router";
import DataService from "@/services/DataService";

const RPT_MATRIZ_RENTAB = 4; //Matriz Rentabilidad
const RPT_BARCHART = 5; //Gráfico de Barras (modal)
const RPT_AVG_DATA = 6; //Average Sales and Avg Margin %

const MR_COLOR1 = "RGB(252, 108, 125)";
const MR_COLOR2 = "RGB(198, 224, 180)";
const MR_COLOR3 = "RGB(0, 176, 240)";
const MR_COLOR4 = "RGB(255, 230, 153)";
const MR_COLOR5 = "RGB(255, 153, 255)"; //ROSA

const props = defineProps({
	start: String,
	end: String,
});

console.log("START-DATE", props.start);
console.log("END-DATE", props.end);

HighchartsMore(Highcharts);

//altura inicial del gráfico
let winH = ref(window.innerHeight - 50 + "px");

const chartContainer = ref();
const chart = ref();

let clientData = [];
let clientServiceCosts = [];

const activeData = ref();
const selectedSector = ref("all");
const typeMg = ref("all");

onMounted(() => {
	//activeData.value = [...clientData];
	//initializeChart();
	window.addEventListener("resize", reflowChart);
});

let averageSales;
let averageMarginPercent;

const rs = new ReportService();
rs.getData({ idReport: RPT_AVG_DATA, dateStart: props.start, dateEnd: props.end }, (data) => {
	//LEO LOS DATOS AVG SALES AND MARGIN %
	averageSales = data[0].avg_sales;
	averageMarginPercent = data[0].avg_margin_perc;
	// RECUPERO LOS DATOS DE LA MATRIZ E INICIALIZO EL GRÁFICO
	rs.getData(
		{ idReport: RPT_MATRIZ_RENTAB, dateStart: props.start, dateEnd: props.end },
		(data) => {
			//Actualizo los colores asociados a cada registro de datos.
			data.forEach((c) => {
				if (c.y < 0) {
					// MG% < 0
					c.color = MR_COLOR1; //ROJO
				} else if (c.y < averageMarginPercent) {
					if (c.x < averageSales) {
						c.color = MR_COLOR5; //ROSA
					} else {
						c.color = MR_COLOR4; //AMARILLO
					}
				} else {
					if (c.x < averageSales) {
						c.color = MR_COLOR2; //VERDE
					} else {
						c.color = MR_COLOR3; //AZUL
					}
				}

				/* Con operadores ternarios sería así..
                c.color =
                c.y < 0
                    ? MR_COLOR1
                    : c.y < averageMarginPercent
                    ? c.x < averageSales
                    ? MR_COLOR1
                    : MR_COLOR4
                    : c.x < averageSales
                    ? MR_COLOR2
                    : MR_COLOR3;
                    */
			});
			clientData = data;
			activeData.value = [...clientData];
			initializeChart();
		}
	);
});

let modal = null;

const generateChart = function (clientName) {
	// Al hacer clic en (x) del modal, se cierra
	document.querySelector(".close").onclick = function () {
		modal.style.display = "none";
		// Ocultar el tooltip cuando se cierra el modal
		/* MS
        if (chart.value.tooltip) {
            chart.value.tooltip.hide = chart.value.tooltip.hideDefault; // Restaurar la funcionalidad de ocultar
            chart.value.tooltip.hide(); // Ocultar el tooltip
        }
        */
	};

	// Cerrar el modal al hacer clic fuera de él
	window.onclick = function (event) {
		if (event.target == modal) {
			modal.style.display = "none";

			/* MS
            if (chart.value.tooltip) {
                chart.value.tooltip.hide = chart.value.tooltip.hideDefault; // Restaurar la funcionalidad de ocultar
                chart.value.tooltip.hide(); // Ocultar el tooltip
            }
            */
		}
	};

	//Pido al backend los datos del cliente
	// Lanzar cuando se hace click en una "bola"
	rs.getData(
		{
			idReport: RPT_BARCHART,
			filterAdd: clientName,
			dateStart: props.start,
			dateEnd: props.end,
		},
		(data) => {
			//detalle de los costes y tiempo derivado de las acciones de los empleado para el cliente para el período seleccionado
			clientServiceCosts = data;

			/*
            [
                { name: 'Acción1', person: 'Empleado1', cost: 10.2728, tiempo: 0.3033 },
                { name: 'Acción2', person: 'Empleado1', cost: 35.1647, tiempo: 1.0383 },
                { name: 'Acción3', person: 'Empleado2', cost: 58.8619, tiempo: 1.7381 },
                ...
            ]

            */

			console.log("clientServiceCosts", clientServiceCosts);

			// Filtrar y agrupar los datos por servicio y persona
			let services = {};
			clientServiceCosts.forEach(function (item) {
				if (!services[item.name]) {
					services[item.name] = {};
				}
				services[item.name][item.person] =
					(services[item.name][item.person] || 0) + item.cost;
			});

			// Preparar series para el gráfico, una por cada persona
			let series = [];
			let personas = new Set(); // Para almacenar nombres únicos de personas
			clientServiceCosts.forEach((item) => personas.add(item.person));

			personas.forEach((persona) => {
				series.push({
					name: persona,
					data: [],
					stack: "costs",
					// Puedes asignar colores específicos por persona o usar una función para generarlos
				});
			});

			// Poblar las series con los datos y crear categorías para el eje X
			let categories = Object.keys(services);
			series.forEach(function (serie) {
				categories.forEach(function (serviceName) {
					serie.data.push(services[serviceName][serie.name] || 0);
				});
			});

			// Calcula el coste total por cliente
			let totalCoste = 0;
			Object.values(services).forEach(function (service) {
				Object.values(service).forEach(function (cost) {
					totalCoste += cost;
				});
			});

			// Calcular totales por persona
			let totalPorPersona = {};
			personas.forEach(function (persona) {
				totalPorPersona[persona] = 0;
				series.forEach(function (serie) {
					if (serie.name === persona) {
						serie.data.forEach(function (dataItem) {
							totalPorPersona[persona] += dataItem.y;
						});
					}
				});
			});

			// Calcular el total de costes por servicio y ordenar
			let servicesTotal = [];
			for (let serviceName in services) {
				let totalCost = Object.values(services[serviceName]).reduce((a, b) => a + b, 0);
				servicesTotal.push({ name: serviceName, total: totalCost });
			}
			servicesTotal.sort(function (a, b) {
				return b.total - a.total;
			});

			// Crear categorías ordenadas para el eje X basadas en servicesTotal
			categories = servicesTotal.map(function (service) {
				return service.name;
			});

			// Ordenar los datos de cada serie según las categorías ordenadas y calcular totales por persona
			totalPorPersona = {};
			series.forEach(function (serie) {
				let dataSorted = [];
				totalPorPersona[serie.name] = 0; // Inicializar total para la persona

				categories.forEach(function (category) {
					let dataPoint = services[category][serie.name] || 0;
					dataSorted.push(dataPoint);
					totalPorPersona[serie.name] += dataPoint; // Sumar al total de la persona
				});

				serie.data = dataSorted; // Asignar los datos ordenados a la serie
			});

			let numCategories = categories.length;
			let heightPerCategory = 20;
			let totalHeight = numCategories * heightPerCategory + 100;

			// Configuración del gráfico
			Highcharts.chart("barChartContainer", {
				chart: {
					type: "bar",
					height: totalHeight,
				},
				credits: {
					enabled: false,
				},
				title: {
					text:
						"Costes por Servicio para " +
						clientName +
						" (" +
						Highcharts.numberFormat(totalCoste, 0, ".", ".") +
						" EUR)",
				},
				xAxis: {
					categories: categories,
				},
				yAxis: {
					min: 0,
					title: {
						text: null,
					},
					labels: {
						formatter: function () {
							// Usa numberFormat para asegurar el uso de separadores de miles
							return Highcharts.numberFormat(this.value, 0, ".", ".");
						},
					},
					stackLabels: {
						enabled: true,
						style: {
							fontWeight: "600",
							fontFamily: "Arial",
							color: "RGB(0, 0, 0)",
							textOutline: "none", // Esto elimina el contorno/borde del texto
						},
						formatter: function () {
							return Highcharts.numberFormat(this.total, 0, ".", ".");
						},
					},
				},
				tooltip: {
					useHTML: true, // Habilita HTML para el contenido del tooltip
					formatter: function () {
						var color = this.series.color; // O el método que uses para obtener el color
						var persona = this.series.name; // O cómo puedas obtener el nombre de la persona
						var valor = Highcharts.numberFormat(this.y, 0, ",", ".");
						// Construye el HTML del tooltip
						var tooltipHtml = `
                                        ${this.x} 
                                        <br/>
                                        <span style="color: ${color}">●</span>
                                        <span>${persona}: </span> 
                                        <span>${valor}</span>
                                    `;
						return tooltipHtml;
					},
				},
				legend: {
					useHTML: true, // Permitir HTML en la leyenda
					// Esta función se llama cada vez que se actualiza la leyenda
					labelFormatter: function () {
						let nombre = this.name;
						let valor = Highcharts.numberFormat(
							totalPorPersona[this.name],
							0,
							",",
							"."
						);
						let valor_porcentaje = Highcharts.numberFormat(
							(totalPorPersona[this.name] / totalCoste) * 100,
							0,
							",",
							"."
						);
						// Utiliza etiquetas HTML con estilos en línea para cambiar el tamaño de la fuente
						return (
							'<span style="font-size: 14px">' +
							nombre +
							"</span><br>" +
							'<span style="font-size: 10px">' +
							valor +
							" (" +
							valor_porcentaje +
							"%)" +
							"</span>"
						);
					},
					reversed: true,
				},

				plotOptions: {
					series: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0.1,
						dataLabels: {
							enabled: true,
							formatter: function () {
								return Highcharts.numberFormat(this.y, 0, ",", ".") > 0
									? Highcharts.numberFormat(this.y, 0, ",", ".")
									: "";
							},
							style: {
								fontWeight: "normal",
								color: "RGB(0, 0, 0)", // o el color que desees para el texto
								textOutline: "none", // Esto elimina el contorno/borde del texto
								fontFamily: "Arial", // Asegúrate de que la fuente sea consistente si también quieres cambiarla aquí
							},
						},
					},
				},
				series: series,
			});
		}
	);
};

const showBarChart = function () {
	let cliDescr = this.name; // Nombre del cliente sobre el que se hizo clic
	console.log("tooltip", chart.value.tooltip);
	// Mostrar el tooltip manualmente
	if (chart.value.tooltip) {
		chart.value.tooltip.refresh(this);
		chart.value.container.getElementsByClassName("highcharts-tooltip")[0].style.zIndex = 9999;
		chart.value.tooltip.update({
			style: {
				pointerEvents: "auto", // Esto permite que el tooltip reciba eventos de ratón
			},
		});
		// Hacer que el tooltip sea no ocultable automáticamente
		chart.value.tooltip.hide = () => null;
	}

	// Mostrar el modal
	modal = document.getElementById("myModal");
	modal.style.display = "block";
	/* MS- Sacar fuera esta función!!!  */

	// Llamada inicial para generar el gráfico con todos los datos
	generateChart(cliDescr);
};

const initializeChart = () => {
	let tempElement;
	Highcharts.setOptions({
		lang: {
			thousandsSep: ".",
			decimalPoint: ",",
		},
	});

	const chartOptions = {
		credits: {
			enabled: false,
		},

		chart: {
			type: "bubble",
			plotBorderWidth: 1,
			zoomType: "xy",
		},

		legend: {
			enabled: false,
		},

		title: {
			text: "Matriz de Rentabilidad por Cliente",
		},

		xAxis: {
			labels: {
				formatter: function () {
					return Highcharts.numberFormat(this.value, 0, ",", ".");
				},
			},

			gridLineWidth: 1,
			title: {
				text: "Ventas (EUR)",
			},
			plotLines: [
				{
					color: "grey",
					dashStyle: "dot",
					width: 2,
					value: averageSales,
					label: {
						rotation: 0,
						y: 15,
						style: {
							fontStyle: "italic",
						},
						//text: 'Media ' + averageSales.toLocaleString()
						text: "Media " + Highcharts.numberFormat(averageSales, 0, ",", "."),
					},
					zIndex: 5,
				},
			],
		},

		yAxis: {
			labels: {
				formatter: function () {
					return this.value + "%"; // Añadirá un signo de porcentaje después del número
				},
			},

			startOnTick: false,
			endOnTick: false,
			title: {
				text: "Margen de Ventas %",
			},
			plotLines: [
				{
					color: "grey",
					dashStyle: "dot",
					width: 2,
					value: averageMarginPercent,
					label: {
						align: "right",
						style: {
							fontStyle: "italic",
						},
						text: "Media " + averageMarginPercent + "%",
						x: -10,
					},
					zIndex: 5,
				},
				{
					value: 0, // Esto coloca la línea en el valor exacto de 0 en el eje Y
					color: "red", // Puedes cambiar el color si lo deseas
					width: 1,
					zIndex: 4, // Asegúrate de que tiene un zIndex alto para que no esté detrás de las burbujas
					label: {
						align: "right",
						style: {
							fontStyle: "italic",
							color: "red",
						},
						text: "0% ",
						x: -10,
					},
				},
			],
		},

		tooltip: {
			useHTML: true,
			style: {
				zIndex: 9999, // Un valor alto para asegurarse de que esté en el frente
			},
			formatter: function () {
				let point = this.point;

				let costes = Highcharts.numberFormat(
					(point.y < 0 ? -1 : 1) * point.z - point.x,
					0,
					",",
					"."
				);
				let salesFormatted = Highcharts.numberFormat(point.x, 0, ",", ".");
				let marginFormatted = Highcharts.numberFormat(point.z, 0);
				console.log(marginFormatted);
				// Creo una instancia de Vue para representar el componente de tooltip
				const tooltipInstance = createApp(ChartTooltip, {
					name: point.name,
					sector: point.sector,
					sales: salesFormatted,
					costs: costes,
					y: point.y,
					margin: marginFormatted,
				});

				// Montamos el componente de tooltip en un elemento HTML temporal
				tempElement = document.createElement("div");
				tooltipInstance.mount(tempElement);

				// Devuelvo el contenido HTML generado por el componente de tooltip
				return tempElement.innerHTML;

				/* MS
                // "this" se refiere al punto actual sobre el que se encuentra el tooltip

                if (point.y >= 0) {
                    costes = Highcharts.numberFormat(point.z - point.x, 0, ',', '.');
                } else {
                    costes = Highcharts.numberFormat(-point.z - point.x, 0, ',', '.');
                }

                let tooltipText = '<table>' +
                    '<tr><th colspan="2"><h3><b>' + point.name + '</b></h3></th></tr>' +
                    '<tr><th style="color: grey;">' + point.sector + '</th></tr>' +
                    '<tr><th>Ventas:</th><td>' + salesFormatted + ' EUR</td></tr>' +
                    '<tr><th>Costes:</th><td>' + costes + ' EUR</td></tr>' +
                    '<tr><th>Margen %:</th><td>' + point.y + '%</td></tr>';

                // Comprobamos si el margen % es menor que 0
                if (point.y < 0) {
                    // Si es así, mostramos un - en lugar del valor del margen EUR
                    tooltipText += '<tr><th>Margen EUR:</th><td>-' + mgFormatted + ' EUR</td></tr>';
                } else {
                    // De lo contrario, mostramos el valor del margen EUR
                    tooltipText += '<tr><th>Margen EUR:</th><td>' + mgFormatted + ' EUR</td></tr>';
                }

                tooltipText += '</table>';
                return tooltipText;
                */
			},
			// No es necesario el footerFormat ya que estamos construyendo todo el contenido manualmente
		},

		plotOptions: {
			bubble: {
				sizeBy: "area", // asegura que el tamaño de la burbuja se base en su área
				zThreshold: 0, // valor máximo de z para el rango de datos, debe ser el valor z más alto que esperas
			},
			series: {
				point: {
					events: {
						//Click en la "bola" -> muestra el detalle de los costes del cliente seleccionado
						click: showBarChart,
					},
				},
			},
		},

		series: [
			{
				data: [...clientData],
			},
		],
	};

	chart.value = Highcharts.chart(chartContainer.value, chartOptions);
	chart.value.series[0].setData(activeData.value, true);
};

//  Conjunto de datos de los sectores
const sectores = ref([]); //['AGRARIO', 'ARRENDAMIENTO', 'AYUNTAMIENTO', 'CÁRNICA', 'CLINICA DENTAL', 'COMERCIO', 'COMUNIDAD DE PROPIETARIOS', 'CONSTRUCCIÓN', 'DERIVADOS DEL CEMENTO', 'EVENTOS', 'FARMACIA', 'GASOLINERA', 'HOSTELERIA', 'MADERA', 'METAL', 'PELUQUERIA', 'PROFESIONALES', 'TRANSPORTE', 'OTROS'];

const ds = new DataService();
ds.getDataItems("sectors", (data) => {
	data.forEach((el) => {
		sectores.value.push(el.des);
	});
});

// Evento de cambio para el filtro
function filterChange() {
	// Esconder el modal
	let modal = document.getElementById("myModal");
	modal.style.display = "none";

	let tooltip = document.getElementsByClassName("chart-tooltip");

	let filteredData = [...clientData];

	if (selectedSector.value != "all") {
		filteredData = filteredData.filter((cli) => cli.sector === selectedSector.value);
		console.log("FILTERED-SECTOR", filteredData);
	}

	if (typeMg.value != "all") {
		filteredData = filteredData.filter((cli) => typeMg.value === cli.tipo_mg);
		console.log("FILTERED-MARGIN", filteredData);
	}

	chart.value.series[0].setData(filteredData, true);
}

function reflowChart() {
	console.log("H: ", window.innerHeight - 50 + "px");
	chartContainer.value.style.height = window.innerHeight - 50 + "px";

	// Para que Highcharts se ajuste al nuevo tamaño puedes llamar a la función 'reflow'
	if (chart.value) {
		chart.value.reflow();
	}
}

function back() {
	router.push({ name: "analysis" });
}
</script>

<style scoped>
/* Estilo básico para el select */
.filter-box {
	position: absolute;
	left: 20px;
	top: 20px;
    z-index: 10;
}
.filter {
	font-family: "Arial", sans-serif;
	font-size: 16px;
	padding: 10px 15px;
	border: 1px solid #cccccc;
	border-radius: 5px;
	outline: none;
	/* Eliminar el contorno al enfocar */
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
	/* Sombra suave para dar profundidad */
	cursor: pointer;
	/* Indica que es un elemento interactivo */
	background-color: white;
	/* Fondo blanco para empezar */
	transition: all 0.3s ease;
	/* Transición suave para cambios de estado */
}

/* Estilo para el select al pasar el mouse */
.filter:hover {
	border-color: #888888;
	/* Oscurecer el borde ligeramente */
	box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
	/* Sombra más pronunciada */
}

/* Estilo para el select al estar en foco */
.filter:focus {
	border-color: #0056b3;
	/* Color más intenso para el foco */
	box-shadow: 0 0 8px rgba(0, 86, 179, 0.5);
	/* Sombra que indica foco */
}

/* Puedes añadir media queries para asegurarte de que se ve bien en dispositivos móviles */
@media (max-width: 768px) {
	.filter {
		width: calc(100% - 30px);
		/* Asegurar que el select no sobrepase el ancho de la pantalla */
		font-size: 14px;
		/* Ajustar el tamaño de la fuente para dispositivos más pequeños */
	}
}

#logo {
	display: block;
	/* O 'inline-block' si prefieres */
	width: 100px;
	/* Ancho de la imagen */
	position: absolute;
	/* Posicionamiento absoluto respecto al contenedor padre */
	right: 20px;
	/* Alineado a la derecha con un margen */
	top: 20px;
	/* Alineado a la parte superior */
	z-index: 10;
	/* Asegúrate de que esté sobre otros elementos */
}

.chart-container {
	margin-top: 20px;
}

.modal {
	display: none;
	/* Hidden by default */
	position: fixed;
	/* Stay in place */
	z-index: 1;
	/* Sit on top */
	left: 0;
	top: 0;
	width: 100%;
	/* Full width */
	height: 100%;
	/* Full height */
	overflow: auto;
	/* Enable scroll if needed */
	/*background-color: rgb(0,0,0); /* Fallback color */
	/*background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
	background-color: transparent;
	/* Cambiar de rgba(0,0,0,0.4) a transparent */
}

.modal-content {
	background-color: #fefefe;
	margin: 5% auto;
	/* 15% from the top and centered */
	padding: 20px;
	border: 1px solid #888;
	width: 80%;
	/* Could be more or less, depending on screen size */
}

.close {
	color: #aaa;
	font-size: 28px;
	font-weight: bold;
	text-align: end;
}

.close:hover,
.close:focus {
	color: black;
	text-decoration: none;
	cursor: pointer;
}
</style>
